<template>
	<!-- 分享日常-->
	<div class="shareDaily">
		<div class="my-card">
			<div class="left">
				<img src="../../../../assets/home/31.png"
					style="width: 30px;height: 30px;position: relative;" />
				<van-field v-model="value" placeholder="请分享宝贝的日常" style="display:width:60%;padding-left: 10px;"
					disabled />
			</div>
			<div class="right" @click="enterDailyRecord">
				<img src="../../../../assets/home/addBtn.png" style="width: 35px;height: 35px;" />
			</div>
		</div>
	</div>
</template>

<script>
	import {
		Field,
		Icon
	} from "vant";

	export default {
		name: 'ShareDaily',
		components: {
			[Field.name]: Field,
			[Icon.name]: Icon,
		},
		data() {
			return {
				value: '',
			}
		},
		mounted() {

		},
		methods: {
			// 点滴记录页面
			enterDailyRecord(){
				this.$router.push('/Home/dailyRecord')
			}
		}
	}
</script>

<style lang="less" scoped>
	.shareDaily {
		.my-card {
			display: flex;
			justify-content: space-between;
			border-radius: 40px;
			padding: 5px;
			padding-left: 8px;
			align-items: center;
			.left {
				display: flex;
				align-items: center;
			}

			.right {
				display: flex;
				align-items: center;
			}
		}
	}
</style>
