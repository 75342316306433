<template>
	<div class="tabbar">
		<van-tabbar v-model="tabbarTempValue" active-color='#47AFA7' :route='true'>
			<van-tabbar-item icon="home-o" replace to="/Home">首页</van-tabbar-item>
			<van-tabbar-item icon="shop-collect-o" replace to="/ClassCircle" v-if="isAdministration == 'false'">班级圈</van-tabbar-item>
			<van-tabbar-item @click="add" v-if="isAdministration == 'false' &&  Principal =='false' ">
				<template>
					<img src="../../assets/home/addBtn.png" style="width: 40px;height: 40px;" />
				</template>
			</van-tabbar-item>
			<van-tabbar-item icon="paid" replace to="/Contact">联系册</van-tabbar-item>

			<van-tabbar-item icon="user-o" replace to="/TeacherMy" v-if="userType == 2">我的</van-tabbar-item>
			<van-tabbar-item icon="user-o" replace to="/ParentMy" v-else>我的</van-tabbar-item>
			<!-- <van-tabbar-item icon="user-o">我的</van-tabbar-item> -->
		</van-tabbar>


		<!-- 底部弹窗 -->
		<van-action-sheet v-model="show" title="成长册" @cancel='show = false'>
			<div class="content">
				<div style="display: flex;align-items: center;padding-left: 20px;" v-show="userType == 3">
					<van-image width="40" height="40" radius='50%' :src="ProfilePicture" class="img" />
					<span style="margin-left: 10px;">{{ChildrenName}}</span>
				</div>
				<!-- 标签切换 -->
				<div class="vanTabs">
					<van-tabs v-model="activeTab" style="padding: 0px;"
						:class="[userType == 2? 'teachWidth':'parentWidth']">
						<van-tab title="家长寄语" class="vanTab" v-if="userType == 3">
							<div class="tabContent" @click='evalute'>
								<img src="../../assets/home/17.png" />
								<div>
									<span>阶段性评价</span>
								</div>
							</div>
						</van-tab>
						<!-- 		<van-tab title="教师寄语" class="vanTab" v-if="userType == 2">
							<div class="tabContent">
								<img src="../../assets/home/17.png" />
								<div>
									<span>阶段性评价</span>
								</div>
							</div>
						</van-tab> -->
						<van-tab title="点滴记录" class="vanTab">
							<div class="tabContent" @click="enterDaily('日常动态')">
								<img src="../../assets/home/18.png" />
								<div>
									<span>日常动态</span>
								</div>
							</div>
							<div class="tabContent" v-if="userType == 3" @click="enterDaily('亲子任务')">
								<img src="../../assets/home/19.png" />
								<div>
									<span>亲子任务</span>
								</div>
							</div>
							<div class="tabContent" v-if="userType == 2" @click="enterDaily('主题课程')">
								<img src="../../assets/home/19.png" />
								<div>
									<span>主题课程</span>
								</div>
							</div>
							<div class="tabContent" v-if="userType == 3" @click="enterDaily('宝贝爱运动')">
								<img src="../../assets/home/20.png" />
								<div>
									<span>宝贝爱运动</span>
								</div>
							</div>
							<div class="tabContent" v-if="userType == 2" @click="enterDaily('节庆活动')">
								<img src="../../assets/home/20.png" />
								<div>
									<span>节庆活动</span>
								</div>
							</div>
						</van-tab>
						<van-tab title="徽章记录" v-if='userType == 3' class="vanTab">
							<div class="tabContent" @click='enterBadgeRecord'>
								<img  src="../../assets/home/21.png"/>
								<div>
									<span>徽章记录</span>
								</div>
							</div>
						</van-tab>
					</van-tabs>
				</div>
			</div>
		</van-action-sheet>

	</div>
</template>

<script>
	import {
		Tabbar,
		TabbarItem,
		Icon,
		ActionSheet,
		Tab,
		Tabs,
		Image as VanImage,
	} from 'vant'
	
	
	export default {
		components: {
			[Tabbar.name]: Tabbar,
			[TabbarItem.name]: TabbarItem,
			[Icon.name]: Icon,
			[ActionSheet.name]: ActionSheet,
			[Tab.name]: Tab,
			[Tabs.name]: Tabs,
			[VanImage.name]: VanImage
		},
		props: {
			active: Number
		},
		data() {
			return {
				userType: window.localStorage.getItem('UserType'),
				ChildrenName: JSON.parse(window.localStorage.getItem('userInfo')).OpenChildren?.ChildrenName,
				ProfilePicture: JSON.parse(window.localStorage.getItem('userInfo')).OpenChildren?.ProfilePicture,
				tabbarTempValue: this.active,
				show: false,
				activeTab: 0, //标签
				ChildrenID:JSON.parse(window.localStorage.getItem('userInfo')).OpenChildren?.ChildrenID,
				isAdministration: window.localStorage.getItem("IsAdministration"),
				Principal: window.localStorage.getItem("Principal"),		
			}
		},
		methods: {
			// 点击弹窗数据
			add() {
				this.show = true
			},
			//寄语页面
			evalute() {
				this.$router.push({
					path: '/evalute',
					query: {
						id: this.ChildrenID
					}
				})
			},
			// 日常动态页面
			enterDaily(name) {
				//console.log(name)
				this.$router.push({
					path: '/Home/dailyRecord',
					query: {
						name
					}
				})
			},
			// 徽章页面
			enterBadgeRecord(){
				this.$router.push({
					path:'/badgeRecord',
					query:{
						id:this.ChildrenID
					}
				})
			}
			// onChange(index) {
			// 	let routerArray = []
			// 	if (this.userType === '2') {
			// 		routerArray = ['/Home', '/FamilyHome','/Contact', '/TeacherMy']
			// 	} else if (this.userType === '3') {
			// 		routerArray = ['/Home', '/FamilyHome','/Contact', '/ParentMy']
			// 	}
			// 	// 添加框不跳转路由
			// 	this.$router.push(routerArray[index])
			// }
		}
	}
</script>
<style lang="less" scoped>
	.tabbar {
		/deep/ .van-icon-add-o:before {
			font-size: 38px;
			color: #ffe500;
		}

		.parentWidth {
			width: 70%;

			/deep/ .vanTab {
				display: flex;
			}
		}

		.teachWidth {
			/deep/ .van-tabs__wrap {
				width: 100px
			}

			/deep/ .vanTab {
				width: 60%;
				display: flex;
			}
		}

		.content {
			padding: 0px 2px 50px;
		}

		/deep/ .van-tabs__line {
			background-color: #47AFA7;
			bottom: 26px;
			width: 57px;
		}

		.tabContent {
			width: 100px;
			text-align: center;

			div {
				span {
					position: relative;
					top: 10px;
				}
			}

			img {
				width: 40px;
				height: 40px;
			}
		}

	}
</style>
